import React, { useEffect, useState } from 'react';
import api from '../../app/api/axios';
import Loader from '../editor/Loader';

function Image({
  image, className, onClick,
}) {
  const [imageSrc, setImageSrc] = useState(null);

  useEffect(() => {
    const fetchImage = async () => {
      try {
        const response = await api.get(image.image, {
          responseType: 'blob',
        });

        if (response.status === 200) {
          const url = URL.createObjectURL(response.data);
          setImageSrc(url);
        } else {
          console.error('Error loading image', response.statusText);
        }
      } catch (error) {
        console.error('Error loading image', error);
      }
    };

    fetchImage();
  }, [image.image]);

  if (!imageSrc) {
    return <Loader />;
  }

  return (
    <img
      // TODO Refactor id
      id={`img_${image.id}`}
      onClick={() => onClick(image)}
      className={className}
      src={imageSrc}
      style={{ width: '100%', display: 'block' }}
      loading="lazy"
      alt={image.id}
    />
  );
}

export default Image;
