import React, { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

function HoldOn(props) {
  const [loader, setLoader] = useState(0);
  const statusRequest = useSelector((state) => state.generation.statusRequest);
  useEffect(() => {
    const interval = setInterval(() => {
      if (loader < 99) {
        setLoader(loader + 1);
      }
      if (statusRequest !== 'pending') {
        setLoader(100);
      }
    }, 700);

    return () => clearInterval(interval);
  }, [loader, statusRequest]);

  return (
    <div className="holdOnWrapper">
      <div className="holdOnContainer">
        <div className="holdOnIconWrapper">
          <span className="progressIcon">
            <span className="progressText">
              {loader}
              <span className="percent">%</span>
            </span>
          </span>
          <span className="holdOnIcon" />
        </div>
        <span className="holdOnText">Hold on, we’re generating your beautiful image!</span>
      </div>
    </div>
  );
}

export default HoldOn;
