import React, {
  useEffect, useRef, useState,
  useCallback,
} from 'react';
import Modal from 'react-modal';
import { useDispatch, useSelector } from 'react-redux';
import { toast } from 'react-toastify';
import { map } from 'lodash';
import { ReactComponent as ImgBlackIcon } from '../../assets/images/icons/img-black.svg';
import SubmitButton from '../forms/SubmitButton';
import ElementsModal from './ElementsModal';
import Describe from './Describe';

import {
  setCurrentImg,
  setImageParams,
  showLeftMenuElement,
  toggleLeftMenuElement,
  togglePickAnImagePage,
} from '../../app/features/editor/editorSlice';
import SaveSettings from './SaveSettings';
import LoadSettings from './LoadSettings';
import { selectOneUserSettings } from '../../app/selectors/settingsSelectors';
import { generationImageAPI } from '../../app/thunks/generation';
import ImageEditorView from './ImageEditorView';
import StyleImage from './StyleImage';
import CanvasRatio from './CanvasRatio';
import {
  currentImageDataUrl, currentImageFile, dataURLtoFile, formDataGeneration, hasAlpha,
} from '../utils/utils';

function LeftSectionEditor() {
  const dispatch = useDispatch();

  const savedData = useSelector(selectOneUserSettings);

  const openedMenu = useSelector((state) => state.editor.openedLeftMenu);
  const leftMenuElements = useSelector((state) => state.editor.leftMenuElements);

  const [data, setData] = useState({
    ratio: '',
    width: 400,
    height: 400,
    describe: '',
    negative_prompt: '',
    style_name: '',
    // composition_name: '',
    // dof_style: '',
    // shutter_speed_style: '',
    // color_filter_style: '',
    // randomness: '',
  });

  const {
    canvas_ratio,
    describe,
    style: styleElement,
    //   adjustments,
    //   color_filters,
    //   randomness,
    //   composition,
  } = leftMenuElements;

  const [toggleModal, setToggleModal] = useState(false);
  const [loadSaveModal, setLoadSaveModal] = useState('');
  const [closePopupAnimation, setClosePopupAnimation] = useState(false);
  const timerRef = useRef(null);

  useEffect(() => {
    if (savedData) {
      if (savedData.describe || savedData.negative_prompt) showLeftMenu('Describe');
      if (savedData.style_name) showLeftMenu('Style');
      // if (savedData.dof_style || savedData.shutter_speed_style) showLeftMenu('Adjustments');
      // if (savedData.composition_name) showLeftMenu('Composition');
      // if (savedData.color_filter_style) showLeftMenu('Color Filters');
      // if (savedData.randomness) showLeftMenu('Randomness');
      setData({ ...savedData });
    }
  }, [savedData]);

  const toggleModalHandle = () => {
    clearTimeout(timerRef.current);
    setClosePopupAnimation(false);
    setToggleModal(!toggleModal);
  };

  const closeModal = () => {
    setClosePopupAnimation(!closePopupAnimation);
    timerRef.current = setTimeout(() => {
      setToggleModal(false);
    }, 200);
  };

  const toggleLoadSaveSettings = (title) => {
    setLoadSaveModal(title);
  };

  const closeLoadSaveModal = () => {
    setLoadSaveModal('');
  };
  const showLeftMenu = (title) => {
    const menuName = title.toLowerCase().replace(' ', '_');
    dispatch(showLeftMenuElement({ menuName }));
    closeModal();
  };

  const toggleLeftMenu = (title) => {
    const menuName = title?.toLowerCase()?.replace(' ', '_');
    dispatch(toggleLeftMenuElement({ menuName }));
  };

  const generateImageHandle = async () => {
    const file = currentImageFile();
    const descInfo = data.style_name ? `${data.describe}, ${data.style_name}` : data.describe;
    const editor = ImageEditorView.getEditor();

    const { contextContainer, width, height } = editor?._graphics?.canvasImage?.canvas;
    const isTransparent = hasAlpha(contextContainer, width, height);

    const image = !isTransparent && { image: file };

    const params = {
      width: data.width || 400,
      height: data.height || 400,
      prompt: descInfo || 'no prompt',
      nprompt: data.negative_prompt,
      quantity: 4,
      ...image,
    };

    const imgDataUrl = currentImageDataUrl();
    const formData = formDataGeneration(params);
    dispatch(togglePickAnImagePage('generate'));
    await dispatch(generationImageAPI(formData)).then(async (res) => {
      if (!res.error) {
        dispatch(setCurrentImg(imgDataUrl));
        dispatch(setImageParams({
          ratio: data.ratio || '1:1',
          width: data.width || 400,
          height: data.height || 400,
          describe: data.describe,
          style_name: data.style_name,
          negative_prompt: data.negative_prompt,
          quantity: 4,
          ...image,
        }));
      } else {
        dispatch(togglePickAnImagePage('edit'));
        toast.error('Something went wrong');
      }
    });
  };

  const onDataChange = useCallback((key, value) => {
    setData((prevState) => ({
      ...prevState,
      [key]: value,
    }));
  }, []);

  const onSelectStyle = useCallback((key, value) => {
    setData((prevState) => {
      if (prevState[key] === value) {
        return {
          ...prevState,
          [key]: '',
        };
      }
      return {
        ...prevState,
        [key]: value,
      };
    });
  }, []);

  const setRatioHandle = useCallback(({ width, height, ratio }) => {
    setData((prevState) => ({
      ...prevState,
      ratio,
      width,
      height,
    }));
  }, []);

  return (
    <div className="leftSectionEditorContainer">
      <div className="topWrapper">
        <div className="titleWrapper">
          <span className="imgIcon"><ImgBlackIcon /></span>
          <span className="titleText">
            {' '}
            Create
            <span className="stageTxt">1/3</span>
          </span>
        </div>

        {canvas_ratio && <CanvasRatio onToggle={toggleLeftMenu} isOpen={openedMenu} setRatioHandle={setRatioHandle} />}
        {describe
                    && (
                    <Describe
                      onToggle={toggleLeftMenu}
                      isOpen={openedMenu}
                      onDescribeChange={(res) => onDataChange('describe', res.target.value)}
                      onNegativePromptChange={(res) => onDataChange('negative_prompt', res.target.value)}
                      savedDataDescribe={data?.describe}
                      savedDataNegativePrompt={data?.negative_prompt}
                    />
                    )}

        {styleElement && (
        <StyleImage
          onToggle={toggleLeftMenu}
          isOpen={openedMenu}
          onChange={(res) => onSelectStyle('style_name', res)}
          value={data?.style_name}
        />
        )}
        {/* {composition && ( */}
        {/*  <Composition */}
        {/*    onToggle={toggleLeftMenu} */}
        {/*    isOpen={openedMenu} */}
        {/*    onChange={(res) => onDataChange('composition_name', res)} */}
        {/*    value={savedData?.composition_name} */}
        {/*  /> */}
        {/* )} */}
        {/* {adjustments && ( */}
        {/*  <Adjustments */}
        {/*    onToggle={toggleLeftMenu} */}
        {/*    isOpen={openedMenu} */}
        {/*    onDofStyleChange={(res) => onDataChange('dof_style', res)} */}
        {/*    onShutterSpeedChange={(res) => onDataChange('shutter_speed_style', res)} */}
        {/*    dofStyleValue={savedData?.dof_style} */}
        {/*    shutterSpeedValue={savedData?.shutter_speed_style} */}
        {/*  /> */}
        {/* )} */}
        {/* {color_filters && ( */}
        {/*  <ColorFilters */}
        {/*    onToggle={toggleLeftMenu} */}
        {/*    isOpen={openedMenu} */}
        {/*    onChange={(res) => onDataChange('color_filter_style', res)} */}
        {/*    value={savedData?.color_filter_style} */}
        {/*  /> */}
        {/* )} */}
        {/* {randomness && ( */}
        {/*  <Randomness */}
        {/*    onToggle={toggleLeftMenu} */}
        {/*    isOpen={openedMenu} */}
        {/*    onChange={(res) => onDataChange('randomness', res)} */}
        {/*    value={savedData?.randomness} */}
        {/*  /> */}
        {/* )} */}

        <SubmitButton title="+ Add new element" onClick={toggleModalHandle} />
        <Modal
          ariaHideApp={false}
          isOpen={toggleModal}
          onRequestClose={closeModal}
          contentLabel="Elements Modal"
          className={`Modal ${closePopupAnimation ? 'close-animated' : 'animated'} `}
          overlayClassName={`Overlay  ${closePopupAnimation ? 'showOverlay' : ''}`}
        >
          <ElementsModal showLeftMenu={showLeftMenu} />
        </Modal>
      </div>
      <div className="bottomWrapper">
        <div className="saveLoadWrapper">
          <SubmitButton
            title="Save Settings"
            mode="white-mode"
            onClick={() => toggleLoadSaveSettings('save')}
          />
          <SubmitButton
            title="Load Settings"
            mode="white-mode"
            onClick={() => toggleLoadSaveSettings('load')}
          />
          <Modal
            ariaHideApp={false}
            isOpen={!!loadSaveModal}
            onRequestClose={closeLoadSaveModal}
            contentLabel="Load Modal"
            className="Modal"
            overlayClassName="Overlay"
          >
            {loadSaveModal === 'save'
                            && (
                            <SaveSettings
                              data={data}
                              closeLoadSaveModal={closeLoadSaveModal}
                            />
                            )}
            {loadSaveModal === 'load' && <LoadSettings closeLoadSaveModal={closeLoadSaveModal} />}
          </Modal>
        </div>
        <SubmitButton title="Generate" onClick={generateImageHandle} />
      </div>
    </div>
  );
}

export default LeftSectionEditor;
