import React from 'react';
import { ReactComponent as LightOnIcon } from '../../assets/images/icons/lightOn.svg';
import { ReactComponent as LightOffIcon } from '../../assets/images/icons/lightOff.svg';
import { ReactComponent as DarkOffIcon } from '../../assets/images/icons/darkOff.svg';
import { ReactComponent as DarkOnIcon } from '../../assets/images/icons/darkOn.svg';
import { ReactComponent as SwitcherIcon } from '../../assets/images/icons/switcher.svg';
import { useLocalStorage } from '../../hooks/useLocalStorage';

function DarkModeSwitcher(props) {
  const [theme, setTheme] = useLocalStorage('theme', 'light');

  const switchHandle = () => {
    const html = document.querySelector('html');

    const button = html.getAttribute('data-theme');
    if (button === 'light') {
      setTheme('dark');
      html.setAttribute('data-theme', 'dark');
    } else {
      setTheme('light');
      html.setAttribute('data-theme', 'light');
    }
  };

  return (
    <div className="darkModeContainer" onClick={switchHandle}>
      {theme === 'dark' ? <span className="lightIcon lightIconOff"><LightOffIcon /></span>
        : <span className="lightIcon"><LightOnIcon /></span>}
      <div className="switcherWrapper">
        <span className={`switcher  ${theme === 'dark' ? 'switcherDark' : ''}`}>
          <SwitcherIcon />
        </span>
      </div>
      {theme === 'dark' ? <span className="darkIcon "><DarkOnIcon width={13} heigh={13} /></span>
        : <span className="darkIcon darkIconOff"><DarkOffIcon width={13} heigh={13} /></span>}
    </div>
  );
}

export default DarkModeSwitcher;
