import ImageEditorView from '../editor/ImageEditorView';

export const dataURLtoFile = (dataurl, filename) => {
  if (!dataurl) return;
  const arr = dataurl.split(',');
  const mime = arr[0].match(/:(.*?);/)[1];
  const bstr = atob(arr[arr.length - 1]);
  let n = bstr.length;
  const u8arr = new Uint8Array(n);
  // eslint-disable-next-line no-plusplus
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n);
  }
  return new File([u8arr], filename, { type: mime });
};

export const blobToFile = (theBlob, fileName) => {
  const myFile = new File([theBlob], fileName);
  return myFile;
};

export const currentImageDataUrl = () => {
  const imageEditor = ImageEditorView?.getEditor();
  const dataURL = imageEditor && imageEditor.toDataURL();
  return dataURL;
};

export const currentImageFile = () => {
  const imageEditor = ImageEditorView?.getEditor();
  const imageName = imageEditor?.getImageName();
  const dataURL = imageEditor?.toDataURL();
  const file = dataURLtoFile(dataURL, imageName);
  return file;
};

export const formDataGeneration = (params) => {
  const formData = new FormData();
  formData.append('width', params.width);
  formData.append('height', params.height);
  formData.append('prompt', params.prompt || 'no prompt');
  formData.append('nprompt', params.nprompt);
  formData.append('quantity', 4);
  params?.image && formData.append('image', params.image);

  return formData;
};

export const formDataGenerationInpainting = (params) => {
  const formData = new FormData();
  formData.append('prompt', params.prompt || 'no prompt');
  formData.append('nprompt', params.nprompt);
  formData.append('quantity', 4);
  formData.append('image1', params.image1);
  formData.append('image2', params.image2);

  return formData;
};

export const saveImage = (imageSrc, imageName, imageUrl) => {
  const extension = (imageUrl.endsWith('/') ? imageUrl.slice(0, -1) : imageUrl).split('.').pop();
  const link = document.createElement('a');
  link.href = imageSrc;
  link.download = `${imageName}.${extension}`;
  document.body.appendChild(link);
  link.click();
  document.body.removeChild(link);
};

export const hasAlpha = (ctx, w, h) => {
  const iData = ctx.getImageData(0, 0, w, h);
  const u32 = new Uint32Array(iData.data.buffer); // reads 1x uint32 instead of 4x bytes
  let i = 0; const
    len = u32.length;

  while (i < len) if (u32[i++] >>> 24) return false; // not transparent?
  return true; // all transparent, all OK
};
