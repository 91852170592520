import React, { useState } from 'react';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as SaveIcon } from '../../assets/images/icons/save.svg';
import Input from '../forms/Input';

function ImageNameEditor({ image, handleUpdateImage }) {
  const [isEditing, setIsEditing] = useState(false);
  const [imageName, setImageName] = useState(image.name);

  const toggleEdit = () => {
    setIsEditing(!isEditing);
  };

  const handleSave = () => {
    toggleEdit();
    handleUpdateImage({
      id: image.id,
      name: imageName,
    });
  };

  const handleChange = (e) => {
    setImageName(e.target.value);
  };
  const handleFocus = (event) => {
    event.target.select();
  };
  return (
    <div className="imgName">
      {isEditing ? (
        <Input
          type="text"
          value={imageName}
          onChange={handleChange}
          onFocus={handleFocus}
          className="projectNameInput"
        />
      ) : (
        <span>{imageName}</span>
      )}
      <span className="tool editIcon toolStroke" onClick={isEditing ? handleSave : toggleEdit}>
        {isEditing ? <SaveIcon /> : <EditIcon />}
      </span>
    </div>
  );
}

export default ImageNameEditor;
