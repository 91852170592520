import { createSlice } from '@reduxjs/toolkit';
import {
  generateImageInpaintingAPI,
  generationImageAPI,
  getGeneratedImagesAPI,
  saveImageAPI,
} from '../../thunks/generation';

const initialState = {
  generatedImages: [],
  loading: false,
  errorMessage: '',
  statusRequest: '',
};

export const generationSlice = createSlice({
  name: 'generation',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(generationImageAPI.pending, (state) => {
        state.loading = true;
        state.statusRequest = 'pending';
      })
      .addCase(generationImageAPI.fulfilled, (state, action) => {
        state.generatedImages = action.payload;
        state.loading = false;
        state.statusRequest = 'fulfilled';
      })
      .addCase(generationImageAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
        state.statusRequest = 'rejected';
      })
      .addCase(generateImageInpaintingAPI.pending, (state) => {
        state.loading = true;
        state.statusRequest = 'pending';
      })
      .addCase(generateImageInpaintingAPI.fulfilled, (state, action) => {
        state.generatedImages = action.payload;
        state.loading = false;
        state.statusRequest = 'fulfilled';
      })
      .addCase(generateImageInpaintingAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
        state.statusRequest = 'rejected';
      })
      .addCase(getGeneratedImagesAPI.pending, (state) => {
        state.loading = true;
        state.statusRequest = 'pending';
      })
      .addCase(getGeneratedImagesAPI.fulfilled, (state, action) => {
        state.generatedImages = action.payload;
        state.loading = false;
        state.statusRequest = 'fulfilled';
      })
      .addCase(getGeneratedImagesAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
        state.statusRequest = 'rejected';
      })
      .addCase(saveImageAPI.pending, (state, action) => {
      })
      .addCase(saveImageAPI.fulfilled, (state, action) => {
      })
      .addCase(saveImageAPI.rejected, (state, action) => {
      });
  },
});

export default generationSlice.reducer;
