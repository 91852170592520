import React from 'react';
import Modal from 'react-modal';
import { ReactComponent as XIcon } from '../../assets/images/icons/close.svg';
import StyleBox from '../editor/StyleBox';
import SubmitButton from '../forms/SubmitButton';
import ImageNameEditor from './ImageNameEditor';
import ImageFavoriteEditor from './ImageFavoriteEditor';

const ImageModal = React.memo(
  function ImageModal({
    isOpen, image, onClose, handleUpdateImage, handleDeleteImage, handleSaveImage, handleShareImage, editFromImageHandle,
  }) {
    if (!isOpen || !image) return null;

    const { describe, style_name, ratio } = image.options ? JSON.parse(image.options || '{}') : {};

    return (
      <Modal
        ariaHideApp={false}
        isOpen={isOpen}
        onRequestClose={onClose}
        contentLabel="Image Modal"
        className="Modal"
        overlayClassName="Overlay"
      >
        <div className="imgModalContent">
          <div className="closeContainer">
            <span className="closeIcon" onClick={onClose}>
              <XIcon />
            </span>
          </div>
          <div className="imgContainerGallery">
            <img
              alt="Gallery"
              src={image.imageSrc || image.image}
              className="popupImg"
            />
          </div>
          <ImageNameEditor image={image} handleUpdateImage={handleUpdateImage} />
          <span className="tipTitleTxt">Prompt</span>
          <span className="tipTitleDesc">{describe}</span>
          <span className="tipTitleTxt">Settings</span>
          <div className="selectedStyles">
            {style_name && (
              <StyleBox
                className="iconOpacity"
                title={style_name}
                img={`${style_name?.toLowerCase()}.png`}
              />
            )}
            {ratio && (
              <StyleBox
                className="iconOpacity"
                title={ratio}
                img={`${ratio?.toLowerCase()}.svg`}
              />
            )}
          </div>
          <span className="lineHorizontal" />
          <SubmitButton
            title="Start from this image to generate a new one (i2i)"
            onClick={editFromImageHandle}
          />
          <div className="buttonsWrapper">
            <SubmitButton
              mode="white-small-mode"
              title="Share..."
              icon="share.svg"
              onClick={() => handleShareImage(image)}
            />
            <SubmitButton
              mode="white-small-mode"
              title="Download"
              icon="download.svg"
              onClick={() =>
                handleSaveImage(
                  image.imageSrc || image.image,
                  image.name,
                  image.image
                )
              }
            />
            <ImageFavoriteEditor image={image} handleUpdateImage={handleUpdateImage} />
            <span className="line" />
            <SubmitButton
              mode="red-mode"
              title="Delete"
              icon="trash-pink.svg"
              onClick={() => handleDeleteImage(image.id)}
            />
          </div>
        </div>
      </Modal>
    );
  }
);

export default ImageModal;