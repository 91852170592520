import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import Input from '../forms/Input';
import { ReactComponent as SaveIcon } from '../../assets/images/icons/save.svg';
import { createUserSettingsAPI } from '../../app/thunks/settings';

function SaveSettings(props) {
  const dispatch = useDispatch();
  const [title, setTitle] = useState('');

  const handleSaveSettings = () => {
    dispatch(createUserSettingsAPI({ ...props.data, name: title })).then(() => {
      props.closeLoadSaveModal();
    });
  };

  return (
    <div className="saveElementContainer">
      <span className="titleModal">Save settings</span>
      <button disabled={!title} className="saveInputContainer">
        <Input placeholder="My Preset" className="saveInput" onChange={(value) => setTitle(value.target.value)} />
        <span className="saveIconWrapper" onClick={handleSaveSettings}><SaveIcon /></span>
      </button>
    </div>
  );
}

export default SaveSettings;
