import React from 'react';

function ElementComponent({
  title, desc, icon, descBold, showLeftMenu,
}) {
  return (
    <div className="elementComponentWrapper" onClick={() => showLeftMenu(title)}>
      <span className="elementIconContainer"><img width={50} height={50} src={`/assets/images/${icon}`} alt={title} /></span>
      <div className="elementInfoContainer">
        <span className="titleTxt">{title}</span>
        <span className="descTxt">{desc}</span>
        <span className="descTxtBold">{descBold}</span>
      </div>
      <span className="plusIcon">+</span>
    </div>
  );
}

export default ElementComponent;
