import React, { useEffect, useState } from 'react';
import SectionBox from './SectionBox';
import StyleBox from './StyleBox';

const STYLE_IMAGES_LIST_TOP = [
  {
    title: 'Realistic',
    img: 'realistic.png',
  },
  {
    title: 'Surreal',
    img: 'surreal.png',
  },
  {
    title: 'Abstract',
    img: 'abstract.png',
  },
  {
    title: 'Drawing',
    img: 'drawing.png',
  },
  {
    title: 'Fantasy',
    img: 'fantasy.png',
  },
];
const STYLE_IMAGES_LIST_BOTTOM = [
  {
    title: 'Sci-Fi',
    img: 'sci-fi.png',
  },
  {
    title: 'Minimal',
    img: 'minimal.png',
  },
  {
    title: 'Pop Art',
    img: 'pop-art.png',
  },
  {
    title: '3D',
    img: '3d.png',
  },
  {
    title: 'Cartoon',
    img: 'cartoon.png',
  },
];

function StyleImage({
  onToggle, isOpen, onChange, value,
}) {
  const [activeItem, setActiveItem] = useState(value ?? STYLE_IMAGES_LIST_TOP[0].title);

  const handleOnClick = (name) => {
    setActiveItem(name);
    onChange(name);
  };

  useEffect(() => {
    setActiveItem(value);
  }, [value]);

  return (
    <SectionBox title="Style" onToggle={onToggle} isOpen={isOpen === 'style'}>
      <div className="sectionBoxContainer">
        <span className="tipTitleTxt">Pick an image style</span>
        <div className="stylesContainer">
          {STYLE_IMAGES_LIST_TOP ? STYLE_IMAGES_LIST_TOP.map((item, index) => (
            <StyleBox
              key={index}
              title={item.title}
              img={item.img}
              active={activeItem === item.title}
              onClick={() => handleOnClick(item.title)}
            />
          )) : null}
        </div>
        <div className="stylesContainer">
          {STYLE_IMAGES_LIST_BOTTOM ? STYLE_IMAGES_LIST_BOTTOM.map((item, index) => (
            <StyleBox
              key={index}
              title={item.title}
              img={item.img}
              active={activeItem === item.title}
              onClick={() => handleOnClick(item.title)}
            />
          )) : null}
        </div>
      </div>
    </SectionBox>
  );
}

export default StyleImage;
