import { createSlice } from '@reduxjs/toolkit';
import {
  createUserSettingsAPI,
  deleteUserSettingsAPI,
  getOneUserSettingsAPI,
  getUserSettingsAPI,
} from '../../thunks/settings';
import { editorSlice } from '../editor/editorSlice';

// Define the initial state
const initialState = {
  settings: null,
  oneSetting: null,
  loading: false,
  errorMessage: '',
};

export const settingsSlice = createSlice({
  name: 'settings',
  initialState,
  reducers: {
    setSettings: (state, action) => {
      state.oneSetting = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getUserSettingsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getUserSettingsAPI.fulfilled, (state, action) => {
        state.settings = action.payload;
        state.loading = false;
      })
      .addCase(getUserSettingsAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(getOneUserSettingsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(getOneUserSettingsAPI.fulfilled, (state, action) => {
        state.oneSetting = action.payload;
        state.loading = false;
      })
      .addCase(getOneUserSettingsAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(createUserSettingsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(createUserSettingsAPI.fulfilled, (state, action) => {
        state.oneSetting = action.payload;
        state.loading = false;
      })
      .addCase(createUserSettingsAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      })
      .addCase(deleteUserSettingsAPI.pending, (state) => {
        state.loading = true;
      })
      .addCase(deleteUserSettingsAPI.fulfilled, (state) => {
        state.loading = false;
      })
      .addCase(deleteUserSettingsAPI.rejected, (state, action) => {
        state.loading = false;
        state.errorMessage = action.payload;
      });
  },
});

export const { setSettings } = settingsSlice.actions;
export default settingsSlice.reducer;
