import React, { useEffect, useState } from 'react';
import Masonry from 'react-responsive-masonry';
import { map } from 'lodash';
import Modal from 'react-modal';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import Image from './Image';
import ImageNameEditor from './ImageNameEditor';
import ImageFavoriteEditor from './ImageFavoriteEditor';
import { useDeleteImageMutation, useGetImagesQuery, useUpdateImageMutation } from '../../app/service/imageApi';
import { ReactComponent as XIcon } from '../../assets/images/icons/close.svg';
import { ReactComponent as ArchiveIcon } from '../../assets/images/icons/archive.svg';
import { ReactComponent as DotsIcon } from '../../assets/images/icons/dots.svg';
import StyleBox from '../editor/StyleBox';
import SubmitButton from '../forms/SubmitButton';
import { saveImage } from '../utils/utils';
import { setCurrentImg } from '../../app/features/editor/editorSlice';

function Gallery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imgModalToggle, setImgModalToggle] = useState(null);
  const [typeSelection, setTypeSelection] = useState('Newer');
  const {
    data, error, isLoading, refetch,
  } = useGetImagesQuery();

  useEffect(() => {
    refetch();
  }, []);

  const [updateImage] = useUpdateImageMutation();
  const [deleteImage] = useDeleteImageMutation();

  const viewData = React.useMemo(() => {
    if (!data) return [];

    let selectionData = [...data];

    switch (typeSelection) {
      case 'Newer':
        selectionData = selectionData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;
      case 'Older':
        selectionData = selectionData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        break;
      case 'Favorites':
        selectionData = selectionData.filter((item) => item.favorite);
        break;
      case 'Random':
        selectionData = selectionData.sort(() => Math.random() - 0.5);
        break;
      default:
        break;
    }

    return selectionData;
  }, [data, typeSelection]);

  const handleTypeSelectionChange = (newTypeSelection) => {
    setTypeSelection(newTypeSelection);
  };

  const getClassName = (type) => (typeSelection === type ? 'active' : '');

  const openModalHandle = (image) => {
    setImgModalToggle(image);
  };

  const closeLoadSaveModal = () => {
    setImgModalToggle(null);
  };

  const handleShareImage = async (image) => {
    await handleUpdateImage({
      id: image.id,
      public: true,
    });
    refetch();
    await navigator?.clipboard?.writeText(image?.image);
  };

  const handleUpdateImage = async (updatedData) => {
    try {
      await updateImage({ data: updatedData }).unwrap();
      refetch();
      if (typeSelection === 'Favorites') {
        setImgModalToggle(null);
      }
    } catch (error) {
      console.error('Failed to update image:', error);
    }
  };

  const handleDeleteImage = async (id) => {
    try {
      await deleteImage({ id }).unwrap();
      refetch();
      setImgModalToggle(null);
    } catch (error) {
      console.error('Failed to delete image:', error);
    }
  };

  const handleSaveImage = (imageSrc, imageName, imageUrl) => {
    saveImage(imageSrc, imageName, imageUrl);
  };

  const editFromImageHandle = () => {
    const selectedImage = document.getElementById(`img_${imgModalToggle?.id}`).src;
    dispatch(setCurrentImg(selectedImage));
    navigate('/editor');
  };

  const { describe, style_name, ratio } = imgModalToggle?.options ? JSON.parse(imgModalToggle.options || '{}') : {};

  if (!data?.length) return null;
  return (
    <div className="galleryWrapper">
      <div className="galleryContainer">
        <div className="galleryHeader">
          <div className="galleryTitleWrapper">
            <ArchiveIcon />
            <p className="galleryTitle">Your Gallery</p>
          </div>
          <div className="orderWrapper">
            <span className="orderTitle">Order by:</span>
            <span className={getClassName('Newer')} onClick={() => handleTypeSelectionChange('Newer')}>Newer</span>
            <span className={getClassName('Older')} onClick={() => handleTypeSelectionChange('Older')}>Older</span>
            <span className={getClassName('Favorites')} onClick={() => handleTypeSelectionChange('Favorites')}>Favorites</span>
            <span className={getClassName('Random')} onClick={() => handleTypeSelectionChange('Random')}>Random</span>
            <span className="line" />
            <span className="dotsIconWrapper"><DotsIcon /></span>
          </div>
        </div>
        <div className="galleryContent">
          <div className="masonryWrapper">
            <Masonry columnsCount={5} gutter="3px">
              {map(viewData, (image, i) => (
                <Image
                  key={image.id}
                  image={image}
                  className="galleryImg"
                  onClick={openModalHandle}
                />
              ))}
            </Masonry>
            <Modal
              ariaHideApp={false}
              isOpen={!!imgModalToggle}
              onRequestClose={closeLoadSaveModal}
              contentLabel="Load Modal"
              className="Modal"
              overlayClassName="Overlay"
            >
              {imgModalToggle
                && (
                <div className="imgModalContent">
                  <div className="closeContainer"><span className="closeIcon" onClick={closeLoadSaveModal}><XIcon /></span></div>
                  <div className="imgContainerGallery">
                    <img
                      alt="Gallery"
                      src={document.getElementById(`img_${imgModalToggle.id}`).src}
                      className="popupImg"
                    />
                  </div>
                  <ImageNameEditor image={imgModalToggle} handleUpdateImage={handleUpdateImage} />
                  <span className="tipTitleTxt">Prompt</span>
                  <span className="tipTitleDesc">{describe}</span>
                  <span className="tipTitleTxt">Settings</span>
                  <div className="selectedStyles">
                    {style_name && <StyleBox className="iconOpacity" title={style_name} img={`${style_name?.toLowerCase()}.png`} />}
                    {ratio && <StyleBox className="iconOpacity" title={ratio} img={`${ratio?.toLowerCase()}.svg`} />}
                  </div>
                  <span className="lineHorizontal" />
                  <SubmitButton title="Start from this image to generate a new one (i2i)" onClick={editFromImageHandle} />
                  <div className="buttonsWrapper">
                    <SubmitButton
                      mode="white-small-mode"
                      title="Share..."
                      icon="share.svg"
                      onClick={() => handleShareImage(imgModalToggle)}
                    />
                    <SubmitButton
                      mode="white-small-mode"
                      title="Download"
                      icon="download.svg"
                      onClick={() => handleSaveImage(document.getElementById(`img_${imgModalToggle.id}`).src, imgModalToggle.name, imgModalToggle.image)}
                    />
                    <ImageFavoriteEditor image={imgModalToggle} handleUpdateImage={handleUpdateImage} />
                    <span className="line" />
                    <SubmitButton
                      mode="red-mode"
                      title="Delete"
                      icon="trash-pink.svg"
                      onClick={() => handleDeleteImage(imgModalToggle.id)}
                    />
                  </div>
                </div>
                )}
            </Modal>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Gallery;
