import { configureStore } from '@reduxjs/toolkit';
import counterReducer from './features/counter/counterSlice';
import editorReducer from './features/editor/editorSlice';
import mainReducer from './features/main/mainSlice';
import { userApi } from './service/userApi';
import authReducer from './features/auth/authSlice';
import gallerySlice from './features/gallery/gallerySlice';
import { imageApi } from './service/imageApi';
import settingsSlice from './features/settings/settingsSlice';
import generationSlice from './features/generation/generationSlice';

export default configureStore({
  reducer: {
    [userApi.reducerPath]: userApi.reducer,
    [imageApi.reducerPath]: imageApi.reducer,
    counter: counterReducer,
    editor: editorReducer,
    main: mainReducer,
    auth: authReducer,
    gallery: gallerySlice,
    settings: settingsSlice,
    generation: generationSlice,
  },
  middleware: (getDefaultMiddleware) => getDefaultMiddleware().concat(
    userApi.middleware,
    imageApi.middleware
  ),
});
