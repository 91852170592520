import { createApi } from '@reduxjs/toolkit/query/react';
import { authFetchBaseQuery } from './authFetchBaseQuery';

export const imageApi = createApi({
  reducerPath: 'image',
  baseQuery: authFetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_URL }),
  endpoints: (builder) => ({
    getImages: builder.query({
      query: () => ({
        url: '/images/',
        method: 'GET',
      }),
    }),
    getImage: builder.query({
      query: ({ id }) => ({
        url: `/images/${id}/`,
        method: 'GET',
      }),
    }),
    updateImage: builder.mutation({
      query: ({ data }) => ({
        url: '/images/',
        method: 'POST',
        body: data,
      }),
    }),
    deleteImage: builder.mutation({
      query: ({ id }) => ({
        url: '/images/',
        method: 'DELETE',
        body: { id },
      }),
    }),
  }),
});

export const {
  useGetImagesQuery,
  useGetImageQuery,
  useUpdateImageMutation,
  useDeleteImageMutation,
} = imageApi;
