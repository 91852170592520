import api from './axios';

const generationApi = {
  generateImage(data) {
    const hasImage = data.get('image');
    return api.post(hasImage ? '/generate-image-with-image/' : '/generate-image/', data);
  },

  generateImageInpainting(data) {
    return api.post('/generate-image-inpainting/', data);
  },

  getGeneratedImages(data) {
    return api.get(data, { baseURL: '', responseType: 'blob' });
  },

  saveImage(data) {
    return api.post('/images/', data);
  },

};

export default generationApi;
