import React, { memo } from 'react';
import SectionBox from './SectionBox';
import Input from '../forms/Input';

function Describe({
  onToggle, isOpen, onDescribeChange, onNegativePromptChange, savedDataDescribe, savedDataNegativePrompt,
}) {
  return (
    <SectionBox title="Describe" onToggle={onToggle} isOpen={isOpen === 'describe'}>
      <span className="tipTitleTxt">Describe the image with additional detail</span>
      <textarea value={savedDataDescribe} onChange={onDescribeChange} placeholder="Describe" className="questionTextArea" />
      <span className="tipTitleTxt">Negative prompts</span>
      <Input value={savedDataNegativePrompt} onChange={onNegativePromptChange} placeholder="Add negative prompt" className="negativePromptInput" />
    </SectionBox>
  );
}

export default memo(Describe);
