import React, { useState } from 'react';
import SubmitButton from '../forms/SubmitButton';

function ImageFavoriteEditor({ image, handleUpdateImage }) {
  const [isFavorite, setIsFavorite] = useState(image.favorite);

  const toggleFavorite = () => {
    const newFavoriteValue = !isFavorite;
    setIsFavorite(newFavoriteValue);

    handleUpdateImage({
      id: image.id,
      favorite: newFavoriteValue,
    });
  };

  return (
    <SubmitButton
      mode="white-small-mode"
      title={isFavorite ? 'Del from favorites' : 'Add to favorites'}
      icon={isFavorite ? 'favorite.svg' : 'no-favorite.svg'}
      onClick={toggleFavorite}
    />
  );
}

export default ImageFavoriteEditor;
