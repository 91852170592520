import React, { memo } from 'react';
import SectionBox from './SectionBox';
import Input from '../forms/Input';

function AddMoreDetails({ onAddDetailChange, onNegativePromptChange }) {
  return (
    <SectionBox title="Add more details" isOpen disableToggle>
      <span className="tipTitleTxt">Your description</span>
      <textarea
        onChange={onAddDetailChange}
        placeholder="Type something to add details..."
        className="questionTextArea"
        onKeyDown={(e) => {
          if (e.key === 'Backspace') {
            e.stopPropagation();
          }
        }}
      />
      <span className="tipTitleTxt">Tips</span>
      <span className="tipTitleDesc">
        Improves the description by adding more details to the prompt, for example: “Remove the mountains from the background”, or “Make the hair blonde”.
        If you use additional description in combination with Inpainting, changes will be only applied to the Inpainting selected areas.
      </span>
      <span className="tipTitleTxt">Negative prompts</span>
      <Input placeholder="Add negative prompt" className="negativePromptInput" onChange={onNegativePromptChange} />
    </SectionBox>
  );
}

export default memo(AddMoreDetails);
