import { Navigate } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useAuth } from '../../hooks/useAuth';

export function ProtectedRoute({ children }) {
  const { tokens } = useAuth();
  if (!tokens) {
    // user is not authenticated
    window.location.href = process.env.REACT_APP_LANDING_URL;
    return null;
  }
  return children;
}
