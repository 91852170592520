import api from './axios';

const settingsApi = {
  getUserSettings() {
    return api.get('/user-settings/');
  },
  getOneUserSettings(id) {
    return api.get(`/user-settings/${id}`);
  },
  createUserSettings(data) {
    return api.post('/user-settings/', data);
  },
  deleteUserSettings(id) {
    return api.delete('/user-settings/', {
      data: {
        id,
      },
    });
  },
};

export default settingsApi;
