import React from 'react';

function SubmitButton({
  mode, title, className, disabled, onClick, type, icon,
}) {
  return (
    <button
      onClick={onClick}
      type={type}
      disabled={disabled}
      className={`submitButton  ${mode} ${className || ''}`}
    >
      <span className="submitBtnText">
        {icon && <img className="submitButtonIcon" src={`../../assets/icons/${icon}`} alt={icon} />}
        {title}
      </span>
    </button>
  );
}

export default SubmitButton;
