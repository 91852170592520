import React from 'react';

function StyleBox({
  title, img, active, className, mouseOver, mouseOut, onClick, link,
}) {
  const isSvg = img?.slice(-4);
  const imgSrc = link || `/assets/images/${img}`;
  return (
    <div
      onClick={onClick}
      className={`styleImgWrapper ${active ? 'active' : ''} ${className || ''}`}
      onMouseOver={mouseOver}
      onMouseOut={mouseOut}
    >
      <span className={`styleImgContainer ${isSvg === '.svg' && 'svgDarkToggle'}`}><img className="styleImg" src={imgSrc} alt={title} /></span>
      <span className="styleNameText">{title}</span>
    </div>
  );
}

export default StyleBox;
