import React from 'react';
import { ReactComponent as CheckIcon } from '../../assets/images/icons/small-check.svg';

function IconStyleBox({
  title, icon, active, className, checked, onClick,
}) {
  return (
    <div onClick={onClick} className={`styleBoxIconWrapper ${active ? 'active' : ''} ${className || ''}`}>
      <span className="styleBoxIcon">
        {checked && <span className="checkIcon"><CheckIcon /></span> }
        <img alt={title} src={require(`../../assets/images/icons/${icon}`)} />
      </span>
      <span className="styleBoxIconText">{title}</span>
    </div>
  );
}

export default IconStyleBox;
