import './assets/styles/style.scss';
import { BrowserRouter, Route, Routes } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import Home from './pages/home';
import Editor from './pages/editor';
import ImageUpload from './pages/uploadImage/ImageUpload';
import { AuthProvider } from './hooks/useAuth';
import { ProtectedRoute } from './pages/protectedRoute/ProtectedRoute';

import 'react-toastify/dist/ReactToastify.css';
import { useLayoutEffect } from 'react';

function App() {
  useLayoutEffect(() => {
    const currentTheme = JSON.parse(localStorage.getItem('theme')) || 'light';
    const html = document.querySelector('html');
    html.setAttribute('data-theme', currentTheme);
  }, []);

  return (
    <AuthProvider>
      <BrowserRouter basename="/">
        <Routes>
          <Route index path="/" element={<Home />} />
          {/* <Route index path="/image-upload" element={<ImageUpload />} /> */}
          <Route
            index
            path="/image-upload"
            element={(
              <ProtectedRoute>
                <ImageUpload />
              </ProtectedRoute>
                    )}
          />
          <Route
            index
            path="/editor"
            element={(
              <ProtectedRoute>
                <Editor />
              </ProtectedRoute>
                    )}
          />
        </Routes>
      </BrowserRouter>

      <ToastContainer />
    </AuthProvider>

  );
}

export default App;
