import React from 'react';
import ElementComponent from './ElementComponent';

function ElementsModal({ showLeftMenu }) {
  return (
    <div className="elementsModalContainer">
      <span className="titleElementsModal">+ Add new element</span>
      <ElementComponent
        showLeftMenu={showLeftMenu}
        title="Style"
        icon="style-icon.png"
        desc="Add a specific design style to your image: Realistic, Surreal, Cartoon, 3D, Sketch etc."
      />
      {/* <ElementComponent showLeftMenu={showLeftMenu} title="Composition"
       icon="composition-icon.png" desc="Add a specific composition mode: Closeup, panorama, portrait, macro etc." /> */}
      {/* <ElementComponent showLeftMenu={showLeftMenu} title="Adjustments"
      icon="adjustment-icon.png" desc="Add control on advanced camera settings such as Depth of Field and Shutter Speed." /> */}
      {/* <ElementComponent showLeftMenu={showLeftMenu} title="Color Filters"
      icon="composition-icon.png" desc="Add a specific color filter to your image." /> */}
      {/* <ElementComponent */}
      {/*  showLeftMenu={showLeftMenu} */}
      {/*  title="Randomness" */}
      {/*  icon="randomness-icon.png" */}
      {/*  desc="Control randomness for more unexpected results. " */}
      {/*  descBold="Only works with i2i workflows." */}
      {/* /> */}
    </div>
  );
}

export default ElementsModal;
