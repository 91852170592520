import React from 'react';
import { Link } from 'react-router-dom';

import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus-icon.svg';
import { ReactComponent as ArrowIcon } from '../../assets/images/icons/arrow.svg';
import { ReactComponent as ArrowRight } from '../../assets/images/icons/arrow-right.svg';

function GenerateBox({
  id, img, generatedImg, title, desc,
}) {
  const link = id === 'from-img' ? 'image-upload' : 'editor';
  return (
    <Link to={link} className="boxWrapper">
      <div className="boxImgContainer">
        <div className="topContainer">
          {!img && (
          <div className="plusIconWrapper">
            <PlusIcon />
          </div>
          )}
          {img && generatedImg && (
          <div className="imgWrapper">
            <div className="imgContainer">
              <img width={100} height={100} src={`/assets/icons/${img}`} alt="No Generated" />
            </div>
            <ArrowIcon />
            <div className="imgContainer">
              <img
                width={100}
                height={100}
                src={`/assets/icons/${generatedImg}`}
                alt="Generated with AI"
              />
            </div>
          </div>
          )}
        </div>
      </div>
      <div className="infoWrapper">
        <div className="titleWrapper">
          <p className="titleText">{title}</p>
          <span className="arrowWrapper">
            <ArrowRight />
          </span>
        </div>
        <p className="descText">{desc}</p>
      </div>
    </Link>
  );
}

export default GenerateBox;
