import { createApi } from '@reduxjs/toolkit/query/react'
import { authFetchBaseQuery } from './authFetchBaseQuery'

export const userApi = createApi({
  reducerPath: 'user',
  baseQuery: authFetchBaseQuery({ baseUrl: process.env.REACT_APP_BACKEND_URL }),
  endpoints: (builder) => ({
    getUser: builder.query({
      query: () => ({
        url: '/user',
        method: 'GET',
      }),
    }),
  }),
})

export const { useGetUserQuery } = userApi
