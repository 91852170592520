import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import { useAuth } from '../../hooks/useAuth';

function Header() {
  const { tokens, user, logout } = useAuth();
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);

  const handleDropdownToggle = () => {
    setIsDropdownOpen(!isDropdownOpen);
  };

  return (
    <header>
      <Link to="/">
        <img width={30} height={29} alt="Singularity" src="/assets/icons/singularity.svg" />
      </Link>
      <nav>
        {tokens && user ? (
          <div className={`dropdownContainer ${isDropdownOpen ? 'open' : ''}`}>
            <button
              className="myAccountBtn"
              type="button"
              onClick={handleDropdownToggle}
            >
              <span>My Account</span>
              <img width={24} height={24} alt="My Account" src="/assets/icons/test-girl.png" />
            </button>
            {isDropdownOpen && (
              <div className="dropdownContent">
                <button
                  className="logoutBtn"
                  type="button"
                  onClick={logout}
                >
                  <img src={'/assets/icons/logout.svg'} alt="Logout Icon" />
                  Logout
                </button>
              </div>
            )}
          </div>
        ) : (
          <Link to={`${process.env.REACT_APP_LANDING_URL}`}>
            <button className="joinBtn" type="button">
              <span>Join for free</span>
            </button>
          </Link>
        )}
      </nav>
    </header>
  );
}

export default Header;