import { createAsyncThunk } from '@reduxjs/toolkit';

import { thunkErrorHandler } from './thunkError';
import settingsApi from '../api/settings';

export const getUserSettingsAPI = createAsyncThunk(
  'settings/getUserSettingsAPI',
  async (_, thunkAPI) => {
    try {
      const res = await settingsApi.getUserSettings();

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const getOneUserSettingsAPI = createAsyncThunk(
  'settings/getOneUserSettingsAPI',
  async (id, thunkAPI) => {
    try {
      const res = await settingsApi.getOneUserSettings(id);

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const createUserSettingsAPI = createAsyncThunk(
  'settings/createUserSettingsAPI',
  async (data, thunkAPI) => {
    try {
      const res = await settingsApi.createUserSettings(data);

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);

export const deleteUserSettingsAPI = createAsyncThunk(
  'settings/deleteUserSettingsAPI',
  async (id, thunkAPI) => {
    try {
      const res = await settingsApi.deleteUserSettings(id);

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);
