import React, { useCallback, useState, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import EditorHeader from '../../components/layouts/EditorHeader';
import { ReactComponent as UploadIcon } from '../../assets/images/icons/upload.svg';
import Loader from '../../components/editor/Loader';
import { setCurrentImg, togglePickAnImagePage } from '../../app/features/editor/editorSlice';

function ImageUpload(props) {
  const navigate = useNavigate();
  const [loadingImg, setLoadingImg] = useState(false);
  const dispatch = useDispatch();

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Escape') {
        navigate('/');
      }
    };
    document.addEventListener('keydown', handleKeyDown);
    return () => {
      document.removeEventListener('keydown', handleKeyDown);
    };
  }, [navigate]);

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onprogress = () => {
        setLoadingImg(true);
      };
      reader.onload = (ev) => {
        // testing loader
        setTimeout(() => {
          setLoadingImg(false);
          dispatch(setCurrentImg(URL.createObjectURL(file)));
          dispatch(togglePickAnImagePage('edit'));
          navigate('/editor');
        }, 2000); // change to real time
        const binaryStr = reader.result;
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  return (
    <div className="editorWrapper">
      <EditorHeader disabled />
      <div className="uploadImageWrapper">
        <div className="uploadImageContainer" {...getRootProps()}>
          {loadingImg ? (
            <div className="loaderWrapper">
              {' '}
              <Loader title="Uploading" />
              {' '}
            </div>
          ) : (
            <>
              <input className="fileInput" {...getInputProps()} />
              <span className="uploadIcon"><UploadIcon /></span>
              <span className="uploadTitle">Upload your image</span>
              <span className="dndTitle">
                or
                <span className="boldDnd">Drag & Drop</span>
                {' '}
                the file here to upload it
              </span>
            </>
          )}
        </div>
      </div>
    </div>
  );
}

export default ImageUpload;
