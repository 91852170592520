import React, { useEffect, useRef, useState } from 'react';
import ImageEditor from 'tui-image-editor';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentImg, toggleIsEmptyRedo, toggleIsEmptyUndo } from '../../app/features/editor/editorSlice';

let imageEditor;

function ImageEditorView(props) {
  const imageEditorRef = useRef();
  const dispatch = useDispatch();
  const currentImg = useSelector((state) => state.editor.currentImg);

  const [isDrawing, setIsDrawing] = useState(false);
  const [points, setPoints] = useState([]);

  useEffect(() => {
    // const { contextContainer, width, height } = imageEditor?._graphics?.canvasImage?.canvas;
    //
    // if (contextContainer) {
    //   const ctx = contextContainer;
    //
    //   const drawLasso = () => {
    //     ctx.clearRect(0, 0, width, height);
    //     if (points.length > 1) {
    //       ctx.beginPath();
    //       ctx.moveTo(points[0].x, points[0].y);
    //       points.forEach((point) => ctx.lineTo(point.x, point.y));
    //       ctx.closePath();
    //       ctx.strokeStyle = 'red';
    //       ctx.stroke();
    //     }
    //   };
    //
    //   drawLasso();
    // }
  }, [imageEditor]);

  const handleMouseDown = (e) => {
    setIsDrawing(true);
    const rect = imageEditorRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPoints([{ x, y }]);
  };

  const handleMouseMove = (e) => {
    if (!isDrawing) return;
    const rect = imageEditorRef.current.getBoundingClientRect();
    const x = e.clientX - rect.left;
    const y = e.clientY - rect.top;
    setPoints((prevPoints) => [...prevPoints, { x, y }]);
  };

  const handleMouseUp = () => {
    setIsDrawing(false);
  };

  useEffect(() => {
    const selectedImg = currentImg || '/assets/images/transparent.png';

    imageEditorRef.current.innerHTML = '';
    imageEditor = new ImageEditor(imageEditorRef.current, {
      cssMaxWidth: 800,
      cssMaxHeight: 800,
      selectionStyle: {
        cornerSize: 20,
        rotatingPointOffset: 70,
      },
      includeUI: {
        menu: ['crop', 'draw', 'shape'],
        loadImage: {
          path: selectedImg,
          name: 'SampleImage.png',
        },
        theme: {
          'common.backgroundColor': 'transparent',
        },
        uiSize: {
          minWidth: 471,
          minHeight: 471,
          width: 800,
          height: 800,
        },
      },

    });

    imageEditor.ui.buttonElements = imageEditor.ui._buttonElements;
    ImageEditorView.getEditor = () => imageEditor;

    imageEditor.on({
      undoStackChanged(length) {
        if (length) {
          dispatch(toggleIsEmptyUndo(false));
        } else {
          dispatch(toggleIsEmptyUndo(true));
        }
      },
      redoStackChanged(length) {
        if (length) {
          dispatch(toggleIsEmptyRedo(false));
        } else {
          dispatch(toggleIsEmptyRedo(true));
        }
      },
    });
    return () => {
      imageEditor.clearUndoStack();
      imageEditor.clearRedoStack();
    };
  }, [currentImg]);

  return (
    <div className="imageEditorViewWrapper" ref={imageEditorRef} id="canvasEditor" />
  );
}

ImageEditorView.getEditor = () => {
  console.error('ImageEditorView is not initialized');
};

export default ImageEditorView;
