import { createAsyncThunk } from '@reduxjs/toolkit';
import authApi from '../api/auth';
import { thunkErrorHandler } from './thunkError';

export const getUserAPI = createAsyncThunk(
  'auth/getUserAPI',
  async (_, thunkAPI) => {
    try {
      const res = await authApi.getUser();

      return res?.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  },
);
