import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  leftMenuElements: {
    canvas_ratio: true,
    describe: true,
    style: false,
    composition: false,
    adjustments: false,
    color_filters: false,
    randomness: false,
  },
  openedLeftMenu: 'canvas_ratio',
  editorSteps: 'edit', // edit, generate, pick, improve
  currentImg: '',
  currentMode: 'NORMAL',
  isEmptyUndo: true,
  isEmptyRedo: true,
  projectName: 'My Project',
  imageParams: {
    width: 400,
    height: 400,
    describe: '',
    negative_prompt: '',
    style_name: '',
    quantity: 4,
    image: null,
  },
};

export const editorSlice = createSlice({
  name: 'editor',
  initialState,
  reducers: {
    showLeftMenuElement: (state, action) => {
      const { menuName } = action.payload;
      state.leftMenuElements[menuName] = true;
    },
    toggleLeftMenuElement: (state, action) => {
      const { menuName } = action.payload;
      state.openedLeftMenu = menuName;
    },
    togglePickAnImagePage: (state, action) => {
      state.editorSteps = action.payload;
    },
    setEditorMode: (state, action) => {
      state.currentMode = action.payload;
    },
    toggleIsEmptyUndo: (state, action) => {
      state.isEmptyUndo = action.payload;
    },
    toggleIsEmptyRedo: (state, action) => {
      state.isEmptyRedo = action.payload;
    },
    changeProjectName: (state, action) => {
      state.projectName = action.payload;
    },
    setCurrentImg: (state, action) => {
      state.currentImg = action.payload;
    },
    setImageParams: (state, action) => {
      state.imageParams = { ...state.imageParams, ...action.payload };
    },
  },
});

export const {
  showLeftMenuElement, toggleLeftMenuElement, toggleGenerateImage,
  togglePickAnImagePage, setCurrentImg, setEditorMode, toggleIsEmptyUndo,
  toggleIsEmptyRedo, changeProjectName, setImageParams,
} = editorSlice.actions;
export default editorSlice.reducer;
