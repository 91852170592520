import React, { useEffect, useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { useDeleteImageMutation, useGetImagesQuery, useUpdateImageMutation } from '../../app/service/imageApi';
import { saveImage } from '../utils/utils';
import { setCurrentImg } from '../../app/features/editor/editorSlice';
import ImageModal from './ImageModal';
import ImageList from './ImageList';

function Gallery() {
  const dispatch = useDispatch();
  const navigate = useNavigate();

  const [imgModalToggle, setImgModalToggle] = useState(null);
  const [typeSelection, setTypeSelection] = useState('Newer');
  const { data, refetch } = useGetImagesQuery();

  const [viewData, setViewData] = useState([]);

  useEffect(() => {
    refetch();
  }, []);

  useEffect(() => {
    if (!data) {
      setViewData([]);
      return;
    }

    let selectionData = [...data];

    switch (typeSelection) {
      case 'Newer':
        selectionData = selectionData.sort((a, b) => new Date(b.created_at) - new Date(a.created_at));
        break;
      case 'Older':
        selectionData = selectionData.sort((a, b) => new Date(a.created_at) - new Date(b.created_at));
        break;
      case 'Favorites':
        selectionData = selectionData.filter((item) => item.favorite);
        break;
      case 'Random':
        selectionData = selectionData.sort(() => Math.random() - 0.5);
        break;
      default:
        break;
    }

    setViewData(selectionData);
  }, [data, typeSelection]);

  const [updateImage] = useUpdateImageMutation();
  const [deleteImage] = useDeleteImageMutation();

  const handleTypeSelectionChange = useCallback((newTypeSelection) => {
    setTypeSelection(newTypeSelection);
  }, []);

  const openModalHandle = useCallback((image) => {
    setImgModalToggle(image);
  }, []);

  const closeLoadSaveModal = useCallback(() => {
    setImgModalToggle(null);
  }, []);

  const handleUpdateImage = useCallback(async (updatedData) => {
    try {
      await updateImage({ data: updatedData }).unwrap();
      refetch();
      if (typeSelection === 'Favorites') {
        setImgModalToggle(null);
      }
    } catch (error) {
      console.error('Failed to update image:', error);
    }
  }, [updateImage, refetch, typeSelection]);

  const handleShareImage = useCallback(async (image) => {
    await handleUpdateImage({
      id: image.id,
      public: true,
    });
    refetch();
    await navigator?.clipboard?.writeText(image?.image);
  }, [handleUpdateImage, refetch]);

  const handleDeleteImage = useCallback(async (id) => {
    try {
      await deleteImage({ id }).unwrap();
      refetch();
      setImgModalToggle(null);
    } catch (error) {
      console.error('Failed to delete image:', error);
    }
  }, [deleteImage, refetch]);

  const handleSaveImage = useCallback((imageSrc, imageName, imageUrl) => {
    saveImage(imageSrc, imageName, imageUrl);
  }, []);

  const editFromImageHandle = useCallback(() => {
    if (!imgModalToggle) return;
    const selectedImage = document.getElementById(`img_${imgModalToggle.id}`).src;
    dispatch(setCurrentImg(selectedImage));
    navigate('/editor');
  }, [dispatch, imgModalToggle, navigate]);

  if (!data?.length) return null;

  return (
    <div className="galleryWrapper">
      <ImageList
        viewData={viewData}
        openModalHandle={openModalHandle}
        typeSelection={typeSelection}
        handleTypeSelectionChange={handleTypeSelectionChange}
      />
      <ImageModal
        isOpen={!!imgModalToggle}
        image={imgModalToggle}
        onClose={closeLoadSaveModal}
        handleUpdateImage={handleUpdateImage}
        handleDeleteImage={handleDeleteImage}
        handleSaveImage={handleSaveImage}
        handleShareImage={handleShareImage}
        editFromImageHandle={editFromImageHandle}
      />
    </div>
  );
}

export default Gallery;