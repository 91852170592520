import React from 'react';
import { useDispatch } from 'react-redux';
import SectionBox from './SectionBox';
import IconStyleBox from './IconStyleBox';
import ImageEditorView from './ImageEditorView';
import { setEditorMode } from '../../app/features/editor/editorSlice';

function ImproveTools(props) {
  const dispatch = useDispatch();

  const lassoHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      // step 1. get options to draw shape from toolbar
      const shapeOptions = {};
      shapeOptions.stroke = '#F4F7F9';
      shapeOptions.strokeWidth = 1;
      shapeOptions.fill = 'rgba(255, 255, 255, 0.20)';

      // step 2. start drawing shape mode
      if (imageEditor.getDrawingMode() !== 'SHAPE') {
        imageEditor.stopDrawingMode();
        imageEditor.setDrawingShape('circle', shapeOptions);
        imageEditor.startDrawingMode('SHAPE');
      }
    }
  };

  const eraseHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    imageEditor.stopDrawingMode();
    dispatch(setEditorMode('NORMAL'));
  };

  const brushHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      dispatch(setEditorMode('FREE_DRAWING'));

      const isCropActive = document.querySelector('.tie-btn-crop').classList.contains('active');
      if (isCropActive) imageEditor.ui.buttonElements.crop.click();
      if (imageEditor) {
        imageEditor.startDrawingMode('FREE_DRAWING', {
          width: 2,
          color: 'white',
        });
      }
    }
  };

  return (
    <SectionBox title="Inpainting" isOpen disableToggle>
      <div className="sectionBoxContainer">
        <span className="tipTitleTxt">Tools</span>
        <div className="stylesContainer adjustmentStyle">
          <IconStyleBox title="Lasso" icon="lasso.svg" onClick={lassoHandle} />
          <IconStyleBox title="Free drawing" icon="brush.svg" onClick={brushHandle} />
          <IconStyleBox title="Erase" icon="eraser.svg" onClick={eraseHandle} />
        </div>
        <span className="tipTitleTxt">Tips</span>
        <span className="tipTitleDesc">
          Select an area of the image that you would like to change.
          Once an inpainting mask has been created, every change that you make on it will be limited to the selected area.
          It’s very useful if you just want to change small portions and details of your image.
        </span>
      </div>
    </SectionBox>
  );
}

export default ImproveTools;
