import React, { memo } from 'react';
import { ReactComponent as SearchIcon } from '../../assets/images/icons/search.svg';
import { ReactComponent as PlusIcon } from '../../assets/images/icons/plus.svg';

function Input({
  type = 'text', disabled, className, searchIcon, plusIcon, onChange, placeholder, value, onFocus,
}) {
  return (
    <div className="inputWrapper">
      <div className="negativePrompts" />
      <div className="inputContainer">
        <input
          type={type}
          placeholder={placeholder}
          onChange={onChange}
          disabled={disabled}
          value={value}
          className={`searchInput ${className || ''}`}
          onFocus={onFocus}
        />
        {searchIcon && <span className="iconWrapper"><SearchIcon /></span>}
        {plusIcon && <span className="plusIconWrapper"><PlusIcon /></span>}
      </div>

    </div>
  );
}

export default memo(Input);
