import { createAsyncThunk } from '@reduxjs/toolkit';
import {thunkErrorHandler} from "./thunkError";
import galleryApi from "../api/gallery";

export const getGalleryAPI = createAsyncThunk(
  'auth/getGalleryAPI',
  async (_, thunkAPI) => {
    try {
      const res = await galleryApi.getUserGallery();

      return res.data;
    } catch (err) {
      return thunkErrorHandler(err, thunkAPI.rejectWithValue);
    }
  }
);
