import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { ReactComponent as CubeIcon } from '../../assets/images/icons/cube.svg';
import { ReactComponent as TrashIcon } from '../../assets/images/icons/trash.svg';
import { deleteUserSettingsAPI, getOneUserSettingsAPI, getUserSettingsAPI } from '../../app/thunks/settings';
import { selectUserSettings } from '../../app/selectors/settingsSelectors';

function LoadSettings(props) {
  const dispatch = useDispatch();
  const settings = useSelector(selectUserSettings);

  useEffect(() => {
    dispatch(getUserSettingsAPI());
  }, [dispatch]);

  const handleDeleteSettings = (id) => {
    dispatch(deleteUserSettingsAPI(id)).then(() => {
      dispatch(getUserSettingsAPI());
    });
  };

  const handleSelectSettings = (id) => {
    dispatch(getOneUserSettingsAPI(id)).then(() => {
      props.closeLoadSaveModal();
    });
  };

  const showDate = (dateString) => new Date(dateString).toLocaleString('default', { day: 'numeric', month: 'long', year: 'numeric' });

  return (
    <div className="saveElementContainer">
      <span className="titleModal">Load settings</span>
      <span className="tipTitleTxt">Loaded</span>

      {settings ? settings.map((item) => (
        <div key={item.id} className="loadSettingsContainer">
          <div className="loadSettingsIcon"><CubeIcon /></div>
          <div className="loadSettingsInfo" onClick={() => handleSelectSettings(item.id)}>
            <span className="settingTitle">{item.name}</span>
            <span className="settingCreate">{showDate(item.created_at)}</span>
            {/* <span className="settingCreate">11 April 2024</span> */}
          </div>
          <div onClick={() => handleDeleteSettings(item.id)} className="trashIconContainer">
            <TrashIcon />
          </div>
        </div>
      )) : <div>Empty</div>}

      {/* <div className='loadSettingsContainer'> */}
      {/*    <div className='loadSettingsIcon'><CubeIcon/></div> */}
      {/*    <div className="loadSettingsInfo"> */}
      {/*        <span className="settingTitle">Polaroid style</span> */}
      {/*        <span className="settingCreate">11 April 2024</span> */}
      {/*    </div> */}
      {/*    <div className='trashIconContainer'> */}
      {/*        <TrashIcon /> */}
      {/*    </div> */}
      {/* </div> */}
      {/* <div className='loadSettingsContainer'> */}
      {/*    <div className='loadSettingsIcon'> */}
      {/*        <CubeIcon /> */}
      {/*    </div> */}
      {/*    <div className="loadSettingsInfo"> */}
      {/*        <span className="settingTitle">Sci-Fi Preset</span> */}
      {/*        <span className="settingCreate">6 April 2024</span> */}
      {/*    </div> */}
      {/*    <div className='trashIconContainer'><TrashIcon /></div> */}
      {/* </div> */}
      {/* <div className='loadSettingsContainer'> */}
      {/*    <div className='loadSettingsIcon'> */}
      {/*        <CubeIcon /> */}
      {/*    </div> */}
      {/*    <div className="loadSettingsInfo"> */}
      {/*        <span className="settingTitle">Manga Preset</span> */}
      {/*        <span className="settingCreate">24 January 2024</span> */}
      {/*    </div> */}
      {/*    <div className='trashIconContainer'><TrashIcon /></div> */}
      {/* </div> */}
    </div>
  );
}

export default LoadSettings;
