import { Link } from 'react-router-dom';
import React, { useCallback, useState } from 'react';
import { HexColorPicker } from 'react-colorful';
import { useDispatch, useSelector } from 'react-redux';
import { useDropzone } from 'react-dropzone';
import OutsideClickHandler from 'react-outside-click-handler/esm/OutsideClickHandler';
import { ReactComponent as CursorIcon } from '../../assets/images/icons/cursor.svg';
import { ReactComponent as BrushIcon } from '../../assets/images/icons/brush.svg';
import { ReactComponent as HandIcon } from '../../assets/images/icons/hand.svg';
import { ReactComponent as ImgIcon } from '../../assets/images/icons/img-black.svg';
import { ReactComponent as UndoIcon } from '../../assets/images/icons/undo.svg';
import { ReactComponent as RedoIcon } from '../../assets/images/icons/redo.svg';
import { ReactComponent as EditIcon } from '../../assets/images/icons/edit.svg';
import { ReactComponent as ZoomIn } from '../../assets/images/icons/zoomIn.svg';
import { ReactComponent as ZoomOut } from '../../assets/images/icons/zoomOut.svg';
import DarkModeSwitcher from './DarkModeSwitcher';
import Input from '../forms/Input';
import ImageEditorView from '../editor/ImageEditorView';
import { changeProjectName, setCurrentImg, setEditorMode } from '../../app/features/editor/editorSlice';

function EditorHeader({ disabled }) {
  const dispatch = useDispatch();

  const isEmptyUndo = useSelector((state) => state.editor.isEmptyUndo);
  const isEmptyRedo = useSelector((state) => state.editor.isEmptyRedo);
  const editorSteps = useSelector((state) => state.editor.editorSteps);
  const projectName = useSelector((state) => state.editor.projectName);

  const [editName, setEditName] = useState(false);
  const [color, setColor] = useState('#000');
  const [isOpenColor, setOpenColor] = useState(false);

  const toggleNameHandle = () => {
    setEditName(!editName);
  };

  const brushHandle = ({ currentColor }) => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      dispatch(setEditorMode('FREE_DRAWING'));

      const isCropActive = document.querySelector('.tie-btn-crop').classList.contains('active');
      if (isCropActive) imageEditor.ui.buttonElements.crop.click();
      if (imageEditor) {
        imageEditor.startDrawingMode('FREE_DRAWING', {
          width: 2,
        });
        imageEditor.ui.draw.actions.setColor(currentColor || color);
      }
    }
  };
  const colorOpenHandle = (e) => {
    setOpenColor(!isOpenColor);
  };

  const colorHandle = (currentColor) => {
    setColor(currentColor);
    brushHandle({ currentColor });
  };

  const handHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      const actions = imageEditor.getActions();

      imageEditor.startDrawingMode('ZOOM');
      imageEditor.zoom({
        x: 100,
        y: 100,
      });
      actions.main.hand();
    }
  };

  const cursorHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    imageEditor.stopDrawingMode();
    dispatch(setEditorMode('NORMAL'));
  };

  const undoHandle = () => {
    if (isEmptyUndo) return;
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      imageEditor.discardSelection();
      setTimeout(() => {
        imageEditor.undo();
      }, 0);
    }
  };

  const redoHandle = () => {
    if (isEmptyRedo) return;
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      imageEditor.discardSelection();
      setTimeout(() => {
        imageEditor.redo();
      }, 0);
    }
  };

  const zoomInHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    imageEditor.ui.buttonElements.zoomIn.click();
  };

  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach((file) => {
      const reader = new FileReader();

      reader.onabort = () => console.log('file reading was aborted');
      reader.onerror = () => console.log('file reading has failed');
      reader.onload = (ev) => {
        const imageEditor = ImageEditorView.getEditor();
        if (imageEditor) {
          dispatch(setCurrentImg(URL.createObjectURL(file)));
          imageEditor.loadImageFromFile(file).then((result) => {
          });
        }
      };
      reader.readAsArrayBuffer(file);
    });
  }, []);

  const zoomOutHandle = () => {
    const imageEditor = ImageEditorView.getEditor();
    imageEditor.ui.buttonElements.zoomOut.click();
  };

  const changeName = (ev) => {
    dispatch(changeProjectName(ev.target.value));
  };

  const handleFocus = (event) => {
    event.target.select();
  };

  const { getRootProps, getInputProps } = useDropzone({
    onDrop,
    accept: {
      'image/*': ['.jpeg', '.png'],
    },
  });

  return (
    <header className={`editorHeaderWrapper ${disabled ? 'disabled' : ''}`}>
      <nav className="leftSectionHeader">
        <span className="imgSpan">
          <Link to="/">
            <img width={30} height={29} alt="Singularity" src="/assets/icons/singularity.svg" />
          </Link>
        </span>
        <span className="line" />
        <span className="tool" onClick={cursorHandle}><CursorIcon /></span>
        {editorSteps === 'edit' && <span className="tool toolStroke" onClick={brushHandle}><BrushIcon /></span>}
        <span className="tool" onClick={handHandle}><HandIcon /></span>
        {/* <span className='tool textTool'><TextIcon/></span> */}
        <span className="colorWrapper">
          {isOpenColor
                    && (
                    <OutsideClickHandler onOutsideClick={colorOpenHandle}>
                      <HexColorPicker
                        className="colorPickerContainer"
                        color={color}
                        onChange={(currentColor) => colorHandle(currentColor)}
                      />
                    </OutsideClickHandler>
                    )}
        </span>
        {editorSteps === 'edit' && (
          <span className="tool" onClick={colorOpenHandle}>
            <svg
              xmlns="http://www.w3.org/2000/svg"
              width="14"
              height="14"
              viewBox="0 0 14 14"
              fill="none"
            >
              <circle cx="7" cy="7" r="6" fill={color} stroke="#070B11" />
            </svg>
          </span>
        )}

        <span className="line" />
        <input className="fileInput" {...getInputProps()} />
        <span className="uploadImageTxt" {...getRootProps()}>
          Upload Image
          <span
            className="tool toolStroke"
          >
            <ImgIcon />
          </span>
        </span>
        <span
          className={`tool undoIcon ${!isEmptyUndo ? 'active' : ''}`}
          onClick={undoHandle}
        >
          <UndoIcon />
        </span>
        <span
          className={`tool redoIcon ${!isEmptyRedo ? 'active' : ''}`}
          onClick={redoHandle}
        >
          <RedoIcon />
        </span>
      </nav>
      <nav className="middleSectionHeader">
        {editName ? (
          <span className="nameInputWrapper">
            <Input
              placeholder="Project Name"
              onChange={changeName}
              value={projectName}
              onFocus={handleFocus}
            />
          </span>
        )
          : <span className="projectName">{projectName}</span>}
        <span className="tool editIcon toolStroke" onClick={toggleNameHandle}><EditIcon /></span>
      </nav>

      <nav className="rightSection">

        <div className="scaleWrapper">
          <span className="tool toolStroke" onClick={zoomInHandle}><ZoomIn/></span>
          {/* <div className="percentWrapper"> */}
          {/*  <span>100%</span> */}
          {/*  <ArrowDown /> */}
          {/* </div> */}
          <span className="tool toolStroke" onClick={zoomOutHandle}><ZoomOut/></span>
        </div>
        <span className="line"/>
        <DarkModeSwitcher/>
        <div className='dropdownContainer'>
          <button
            className="myAccountBtn"
            type="button"
            onClick={() => window.location.href = '/'}
          >
            <span>My Account</span>
            <img width={24} height={24} alt="My Account" src="/assets/icons/test-girl.png"/>
          </button>
        </div>
      </nav>
    </header>
  );
}

export default EditorHeader;
