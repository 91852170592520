import React, { useEffect, useState } from 'react';
import { useSearchParams } from 'react-router-dom';
import Header from '../../components/layouts/Header';
import TopSection from '../../components/home/TopSection';
import Gallery from '../../components/home/Gallery';
import { useAuth } from '../../hooks/useAuth';
import Tips from '../../components/home/Tips';

function Index() {
  const { setTokensData } = useAuth();
  const [searchParams, setSearchParams] = useSearchParams();
  const access_token = searchParams.get('access_token');
  const refresh_token = searchParams.get('refresh_token');
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    if (access_token && refresh_token) {
      setTokensData({
        access_token,
        refresh_token,
      });
      setSearchParams({});
    }
    setLoading(false);
  }, [searchParams]);

  if (loading) {
    return <div>Loading...</div>;
  }

  return (
    <div className="wrapper">
      <Header />
      <TopSection />
      <div className="middleSectionWrapper">
        <Gallery />
        <div className="homeTipsContainer">
          <Tips
            type="homeTip"
            title="Where to start?"
            desc="This is your home screen.
From here you can generate a new image from scratch just
 by describing it with text, generate it from a sketch you drew or out of a pre-existent image.
You can also check and organize the archive of images that you generated,
 take a look at your favorites, delete obsolete images and much more!
Just start by creating your first image!"
            btnText="Cool, let’s start!"
          />
        </div>
      </div>

    </div>
  );
}

export default Index;
