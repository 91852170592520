import React from 'react';
import Masonry from 'react-responsive-masonry';
import { map } from 'lodash';
import { ReactComponent as ArchiveIcon } from '../../assets/images/icons/archive.svg';
import { ReactComponent as DotsIcon } from '../../assets/images/icons/dots.svg';
import Image from './Image';

const ImageList = React.memo(
  function ImageList ({ viewData, openModalHandle, typeSelection, handleTypeSelectionChange }) {
    return (
      <div className="galleryContainer">
        <div className="galleryHeader">
          <div className="galleryTitleWrapper">
            <ArchiveIcon />
            <p className="galleryTitle">Your Gallery</p>
          </div>
          <div className="orderWrapper">
            <span className="orderTitle">Order by:</span>
            <span className={typeSelection === 'Newer' ? 'active' : ''} onClick={() => handleTypeSelectionChange('Newer')}>Newer</span>
            <span className={typeSelection === 'Older' ? 'active' : ''} onClick={() => handleTypeSelectionChange('Older')}>Older</span>
            <span className={typeSelection === 'Favorites' ? 'active' : ''} onClick={() => handleTypeSelectionChange('Favorites')}>Favorites</span>
            <span className={typeSelection === 'Random' ? 'active' : ''} onClick={() => handleTypeSelectionChange('Random')}>Random</span>
            {/*<span className="line" />*/}
            {/*<span className="dotsIconWrapper"><DotsIcon /></span>*/}
          </div>
        </div>
        <div className="galleryContent">
          <div className="masonryWrapper">
            <Masonry columnsCount={5} gutter="3px">
              {map(viewData, (image) => (
                <Image
                  key={image.id}
                  image={image}
                  className="galleryImg"
                  onClick={openModalHandle}
                />
              ))}
            </Masonry>
          </div>
        </div>
      </div>
    );
  }
);

export default ImageList;