import { createSlice } from '@reduxjs/toolkit';
import {getGalleryAPI} from "../../thunks/gallery";

// Define the initial state
const initialState = {
    gallery: null,
    loading: false,
    errorMessage: ''
};

export const gallerySlice = createSlice({
    name: 'gallery',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getGalleryAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getGalleryAPI.fulfilled, (state, action) => {
                state.gallery = action.payload;
                state.loading = false;
            })
            .addCase(getGalleryAPI.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = action.payload;
            })
    }
});

export default gallerySlice.reducer;
