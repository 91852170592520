import React, { useEffect } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import Modal from 'react-modal';
import EditorHeader from '../../components/layouts/EditorHeader';
import LeftSectionEditor from '../../components/editor/LeftSectionEditor';
import RightSectionEditor from '../../components/editor/RightSectionEditor';
import HoldOn from '../../components/main/HoldOn';
import LeftSectionImprove from '../../components/editor/LeftSectionImprove';
import 'tui-image-editor/dist/tui-image-editor.css';
import ImageEditorView from '../../components/editor/ImageEditorView';
import { setCurrentImg, setEditorMode, togglePickAnImagePage } from '../../app/features/editor/editorSlice';
import SubmitButton from '../../components/forms/SubmitButton';
import PickAnImageContent from '../../components/editor/PickAnImageContent';

function Index() {
  // Testing Redux toolkit RTK
  // const res = useGetAllProductQuery();
  const dispatch = useDispatch();

  const currentMode = useSelector((state) => state.editor.currentMode);
  const editorSteps = useSelector((state) => state.editor.editorSteps);

  useEffect(() => () => {
    dispatch(setCurrentImg(''));
    dispatch(togglePickAnImagePage('edit'));
  }, []);

  const onCropApply = () => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      imageEditor.crop(imageEditor.getCropzoneRect()).then(() => {
        imageEditor.stopDrawingMode();
      });
      imageEditor.startDrawingMode('NORMAL');
    }
    dispatch(setEditorMode('NORMAL'));
  };

  const onCropCancel = () => {
    const imageEditor = ImageEditorView.getEditor();
    if (imageEditor) {
      imageEditor.startDrawingMode('NORMAL');
      dispatch(setEditorMode('NORMAL'));
    }
  };

  return (
    <div className={`editorWrapper ${editorSteps === 'generate' ? 'holdOn' : ''}`}>
      <EditorHeader disabled={editorSteps === 'generate'} />
      {editorSteps === 'generate'
        ? <HoldOn />
        : (
          <div className="editorMainContainer">
            <div className="leftSectionEditorWrapper">
              {editorSteps === 'edit' && <LeftSectionEditor />}
              {editorSteps === 'improve' && <LeftSectionImprove />}
            </div>
            <div className="mainSectionEditorWrapper">
              <ImageEditorView />
              {editorSteps === 'edit' && currentMode === 'CROPPER'
                                && <div className="applyBtn"><SubmitButton title="Apply" onClick={onCropApply} /></div>}
              {editorSteps === 'edit' && currentMode === 'CROPPER'
                                && (
                                <div className="cancelBtn">
                                  <SubmitButton
                                    mode="white-small-mode"
                                    title="Cancel"
                                    onClick={onCropCancel}
                                  />
                                </div>
                                )}

              {/* {editorSteps === 'improve' && <div className="imageMenuWrapper"><ImageMenu /></div>} */}
            </div>
            <div className="rightSectionEditorWrapper">
              <RightSectionEditor />
            </div>
            {editorSteps === 'pick' && (
            <Modal
              ariaHideApp={false}
              isOpen
              contentLabel="Load Modal"
              className="Modal-pick-image"
              overlayClassName="Overlay-pick-image"
            >
              <PickAnImageContent />
            </Modal>
            )}
          </div>
        )}
    </div>
  );
}

export default Index;
