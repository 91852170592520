import {
  createContext, useContext, useEffect, useMemo,
} from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocalStorage } from './useLocalStorage';
import { getUserAPI } from '../app/thunks/auth';
import { selectUser } from '../app/selectors/authSelectors';

const AuthContext = createContext(null);

export function AuthProvider({ children }) {
  const dispatch = useDispatch();
  const [tokens, setTokens] = useLocalStorage('tokens', null);
  const user = useSelector(selectUser);

  useEffect(() => {
    dispatch(getUserAPI());
  }, [tokens]);

  const setTokensData = async (data) => {
    const updatedTokens = {
      access: data.access_token,
      refresh: data.refresh_token,
    };

    setTokens(updatedTokens);
  };

  const logout = () => {
    setTokens(null);
    window.location.href = '/';
  };

  const value = useMemo(
    () => ({
      user,
      tokens,
      setTokensData,
      logout,
    }),
    [tokens, user],
  );
  return <AuthContext.Provider value={value}>{children}</AuthContext.Provider>;
}

export const useAuth = () => useContext(AuthContext);
