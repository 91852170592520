import React from 'react';
import { useSelector } from 'react-redux';
import SectionBox from './SectionBox';
import PluginInfo from './PluginInfo';
import Tips from '../home/Tips';
import Input from '../forms/Input';
import LeftSectionEditor from './LeftSectionEditor';
import LeftSectionImprove from './LeftSectionImprove';

function RightSectionEditor(props) {
  const editorSteps = useSelector((state) => state.editor.editorSteps);

  return (

    <div className="rightSectionEditorContainer">

      {editorSteps === 'edit'
          && (
          <Tips
            type="editTip"
            title="How does the canvas ratio work?"
            desc="The first number (left) represents the width, and the second number (right) represents the height.
For example, a 1:1 ratio means the width and height are equal, like a perfect square.
 A 16:9 ratio means the width is 16 units and the height is 9 units, etc.
So, when you hear '2:3' or '16:9,' it's just describing the shape of the canvas."
            btnText="Got it!"
          />
          )}
      {editorSteps === 'improve' && (
      <Tips
        type="improveTip"
        title="You don’t like a portion or element of the photo?"
        desc="In this screen you’re able to use the functions of Inpainting or improve your description.
Inpainting is like digital magic, filling in selected parts of an image using clever algorithms and patterns.
By improving the description, you can add more and more detail to your image, to minimize the freedom of the AI algorithm and make the final outcome more controlled and less unexpected."
        btnText="Understood"
      />
      )}

    </div>
  );
}

export default RightSectionEditor;
