import React from 'react';
import { ReactComponent as TrashIcon } from '../../assets/images/icons/trash.svg';

function PluginInfo({
  icon, title, desc, delIcon,
}) {
  return (
    <div className="pluginsWrapper">
      <img className="pluginIcon" src={`/assets/images/${icon}`} alt="" />
      <div className="pluginInfoContainer">
        <div className="pluginInfo">
          <span className="pluginTitle">{title}</span>
          <span className="pluginDesc">{desc}</span>
        </div>
        {delIcon && <div className="pluginTrashIcon"><TrashIcon /></div>}
      </div>
    </div>
  );
}

export default PluginInfo;
