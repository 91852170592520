import { createSlice } from '@reduxjs/toolkit';
import {getUserAPI} from "../../thunks/auth";

// Define the initial state
const initialState = {
    user: null,
    loading: false,
    errorMessage: ''
};

export const authSlice = createSlice({
    name: 'auth',
    initialState,
    reducers: {
    },
    extraReducers: (builder) => {
        builder
            .addCase(getUserAPI.pending, (state) => {
                state.loading = true;
            })
            .addCase(getUserAPI.fulfilled, (state, action) => {
                state.user = action.payload;
                state.loading = false;
            })
            .addCase(getUserAPI.rejected, (state, action) => {
                state.loading = false;
                state.errorMessage = action.payload;
            })
    }
});

export default authSlice.reducer;
