import React, { useState } from 'react';
import SubmitButton from '../forms/SubmitButton';
import { ReactComponent as ArrowDown } from '../../assets/images/icons/arrow-down.svg';
import { ReactComponent as SendIcon } from '../../assets/images/icons/send.svg';

function Tips({
  title, desc, btnText, type,
}) {
  const items = localStorage.getItem(type) || false;

  const [tipsToggle, setTipsToggle] = useState(!items);
  const [toggleQuestion, setToggleQuestion] = useState(false);

  const tipsToggleHandle = () => {
    setTipsToggle(!tipsToggle);
    localStorage.setItem(type, 'hidden');
  };
  const questionToggleHandle = () => {
    setToggleQuestion(!toggleQuestion);
  };

  const sendMsgHandle = () => {
    setTipsToggle(!tipsToggle);
  };

  return (
    <div className="tipsWrapper">
      <div className="tipsButton" onClick={tipsToggleHandle}>
        <img width={16} height={16} src="/assets/icons/tipIcon.svg" alt="Tips" />
      </div>
      <div className={`tipsContainerClosed ${tipsToggle && 'tipsContainerOpen'}`}>
        <div className="topIconsWrapper">
          <span className="closeIconContainer" onClick={tipsToggleHandle}><img width={12} height={12} src="/assets/icons/closeIcon.svg" alt="Close" /></span>
          <span className="gaiaIconContainer"><img width={165} height={175} src="/assets/icons/gaia.png" alt="Gaia Tips" /></span>
        </div>
        <p className="tipTitle">
          {title}
        </p>
        <p className="tipDesc">
          {desc}
        </p>
        <SubmitButton onClick={tipsToggleHandle} type="button" title={btnText} />
        <div className={`questionWrapper ${toggleQuestion ? 'openQuestion' : ''}`}>
          <div className="questionTitle">
            <span className="questionText">Didn’t find the right information?Ask Gaia a question.</span>
            <span className={`arrowDownWrapper ${toggleQuestion ? 'arrowUpWrapper' : ''}`} onClick={questionToggleHandle}><ArrowDown /></span>
          </div>
          <div className={`textAreaWrapper ${toggleQuestion ? 'textAreaWrapperOpen' : ''}`}>
            <textarea placeholder="Ask a question" className="questionTextArea" />
            <span className="sendIconWrapper" onClick={sendMsgHandle}><SendIcon /></span>
          </div>
        </div>
      </div>
    </div>
  );
}

export default Tips;
