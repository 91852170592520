import React, { memo, useState } from 'react';
import { map } from 'lodash';
import SectionBox from './SectionBox';
import IconStyleBox from './IconStyleBox';
import mediaParams from '../../mediaRatio.json';

function CanvasRatio({ onToggle, isOpen, setRatioHandle }) {
  const [selectedMedia, setSelectedMedia] = useState({
    media: '',
    type: '',
    orientation: '',
    ratio: '',
  });

  const setActiveMedia = (type, value) => {
    setSelectedMedia((prevState) => ({
      ...prevState,
      ratio: '',
      [type]: value,
    }));
    setRatioHandle({ width: '', height: '' });
  };

  const setParamsForImage = (ratio, width, height) => {
    setSelectedMedia((prevState) => ({
      ...prevState,
      ratio,
    }));
    setRatioHandle({ width, height, ratio });
  };

  return (
    <SectionBox title="Canvas Ratio" onToggle={onToggle} isOpen={isOpen === 'canvas_ratio'}>
      <div className="sectionBoxContainer">
        <span className="tipTitleTxt">Pick a media</span>
        <div className="ratioSizeWrapper ratioSizeBigWrapper">
          {map(mediaParams, (media, key) => (
            <IconStyleBox
              key={key}
              icon={`${key}.svg`}
              title={key}
              active={selectedMedia.media === key}
              onClick={() => setActiveMedia('media', key)}
              className="iconStyleBig"
            />
          ))}
        </div>

        {mediaParams[selectedMedia.media] && (
        <>
          <span className="lineHorizontal" />
          <span className="tipTitleTxt">Pick the type</span>
          <div className="ratioSizeWrapper">
            {map(mediaParams[selectedMedia.media], (type, key) => (
              <IconStyleBox
                key={key}
                icon={`${key}.svg`}
                title={key}
                active={selectedMedia.type === key}
                onClick={() => setActiveMedia('type', key)}
              />
            ))}
          </div>
        </>
        )}

        {mediaParams[selectedMedia.media]?.[selectedMedia.type] && (
        <>
          <span className="lineHorizontal" />
          <span className="tipTitleTxt">Choose the orientation</span>
          <div className="ratioSizeWrapper">
            {map(mediaParams[selectedMedia.media]?.[selectedMedia.type], (orientation, key) => (
              <IconStyleBox
                key={key}
                icon={`${key}.svg`}
                title={key}
                active={selectedMedia.orientation === key}
                onClick={() => setActiveMedia('orientation', key)}
              />
            ))}
          </div>
        </>
        )}
        {mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.ratio && (
        <>
          <span className="lineHorizontal" />
          <span className="tipTitleTxt">Pick a ratio</span>
          <div className="ratioSizeWrapper">
            <IconStyleBox
              icon={`${mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.ratio}.svg`}
              title={mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.ratio}
              active={selectedMedia.ratio === mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.ratio}
              onClick={() => setParamsForImage(mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.ratio, mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.width, mediaParams[selectedMedia.media]?.[selectedMedia.type]?.[selectedMedia.orientation]?.height)}
            />
          </div>
        </>
        )}

      </div>
    </SectionBox>
  );
}

export default memo(CanvasRatio);
