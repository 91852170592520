import axios from 'axios';
import { isTokenExpired } from '../helpers';

const api = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

api.interceptors.request.use(async (config) => {
  const tokens = JSON.parse(localStorage.getItem('tokens'));

  if (tokens && isTokenExpired(tokens.access)) {
    try {
      const response = await axios.post(`${process.env.REACT_APP_BACKEND_URL}/token/refresh/`, {
        refresh: tokens.refresh,
      }, {
        headers: {
          'Content-Type': 'application/json',
        },
      });

      if (response.status === 200) {
        const newAccessToken = response.data.access;
        const newRefreshToken = response.data.refresh || tokens.refresh;

        localStorage.setItem('tokens', JSON.stringify({ access: newAccessToken, refresh: newRefreshToken }));

        config.headers.Authorization = `Bearer ${newAccessToken}`;
      } else {
        localStorage.setItem('tokens', null);
        window.location.href = '/';
        return Promise.reject('Failed to refresh access token');
      }
    } catch (error) {
      localStorage.setItem('tokens', null);
      window.location.href = '/';
      return Promise.reject(`Error refreshing access token: ${error}`);
    }
  } else if (tokens) {
    config.headers.Authorization = `Bearer ${tokens.access}`;
  } else {
    return Promise.reject('User is not authorized');
  }

  return config;
}, (error) => Promise.reject(error));

export default api;
