import React from 'react';
import { ReactComponent as ImgIcon } from '../../assets/images/icons/img.svg';
import GenerateBox from './GenerateBox';

const boxesInfo = [
  {
    id: 'from-scratch',
    img: '',
    generatedImg: '',
    title: 'Start from scratch',
    desc: 'Create a new image from scratch by just describing it with a custom sentence.\n'
        + 'This option gives 100% freedom of interpretation to the AI, so the more accurate is your description, the better.',
  },
  {
    id: 'from-img',
    img: 'dog.png',
    generatedImg: 'generated-dog.png',
    title: 'Generate from image',
    desc: 'Upload a pre-existent image/photo that you have saved on your computer and let the software do the work!\n'
        + 'It’ll generate a completely new image based on your parameters. Be surprised with amazing, unexpected results!',
  },
];

function TopSection() {
  return (
    <div className="topSectionWrapper">
      <div className="topSectionContainer">
        <p className="helloTitle">Hi!</p>
        <div className="createTitle">
          <ImgIcon className="createImg" />
          <p className="createTxt">Create</p>
        </div>
        <div className="generateBoxWrapper">
          {boxesInfo.map(({
            id, img, generatedImg,
            title, desc,
          }) => (
            <GenerateBox
              id={id}
              key={id}
              img={img}
              generatedImg={generatedImg}
              title={title}
              desc={desc}
            />
          ))}
        </div>
      </div>
    </div>
  );
}

export default TopSection;
