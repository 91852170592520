import { fetchBaseQuery } from '@reduxjs/toolkit/query/react';
import { isTokenExpired } from '../helpers';

export const authFetchBaseQuery = ({ baseUrl }) => fetchBaseQuery({
  baseUrl,
  prepareHeaders: async (headers) => {
    const tokens = JSON.parse(localStorage.getItem('tokens'));

    if (!tokens) {
      return Promise.reject('User is not authorized');
    }
    if (isTokenExpired(tokens.access)) {
      try {
        const response = await fetch(`${baseUrl}/token/refresh/`, {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ refresh: tokens.refresh }),
        });

        if (response.ok) {
          const data = await response.json();
          const newAccessToken = data.access;
          const newRefreshToken = data.refresh || tokens.refresh;

          localStorage.setItem('tokens', JSON.stringify({ access: newAccessToken, refresh: newRefreshToken }));

          headers.set('Authorization', `Bearer ${newAccessToken}`);
        } else {
          localStorage.setItem('tokens', null);
          window.location.href = '/';
          return Promise.reject('Failed to refresh access token');
        }
      } catch (error) {
        localStorage.setItem('tokens', null);
        window.location.href = '/';
        return Promise.reject(`Error refreshing access token: ${error}`);
      }
    } else {
      headers.set('Authorization', `Bearer ${tokens.access}`);
    }

    return headers;
  },
});
