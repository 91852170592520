import React from 'react';
import { ReactComponent as ArrowDownWhite } from '../../assets/images/icons/arrow-down-white.svg';

function SectionBox({
  title, children, titleImage, disableToggle, onToggle, isOpen,
}) {
  const onToggleHandle = () => {
    if (!disableToggle && onToggle) {
      onToggle(title);
    }
  };

  return (
    <div className="sectionBox">
      <div className="boxTitleWrapper" onClick={onToggleHandle}>
        <div className="titleContainer">
          {titleImage && <img src={`/assets/icons/${titleImage}`} alt="Title" width={18} height={18} />}
          <div className="ratioTxt">{title}</div>
        </div>
        {!disableToggle && (
          <div className={`whiteArrowWrapper ${isOpen ? 'arrowWrapperClose' : ''}`}>
            <ArrowDownWhite />
          </div>
        )}
      </div>
      {isOpen
                && (
                <div className={`boxChildrenWrapper ${!isOpen ? 'boxChildrenWrapperClose' : ''}`}>
                  {children}
                </div>
                )}
    </div>
  );
}

export default SectionBox;
