import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  aaa: false,
};

export const mainSlice = createSlice({
  name: 'main',
  initialState,
  reducers: {
    aaa: (state, action) => {
      const { menuName } = action.payload;
      state.leftMenuElements[menuName] = true;
    },
  },
});

export const { aaa } = mainSlice.actions;
export default mainSlice.reducer;
