export const thunkErrorHandler = (error, api) => {
  console.log('THUNK ERROR: ', error);
  if (error.response.status >= 500) {
    console.error('Error', error.message ?? 'Server error 1');
  } else {
    error.response.data.message
      ? console.error(error.response.data.message)
      : console.error('Error', error.message ?? 'Server error 2');
  }
  if (error.response.data.message) {
    return api(error.response.data.message);
  }
  return api(error.message);
};
