import React, { useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { setCurrentImg, togglePickAnImagePage } from '../../app/features/editor/editorSlice';
import SubmitButton from '../forms/SubmitButton';
import { ReactComponent as PickAPhotoIcon } from '../../assets/images/icons/pick-a-photo.svg';
import StyleBox from './StyleBox';
import SectionBox from './SectionBox';
import { setSettings } from '../../app/features/settings/settingsSlice';
import { currentImageFile, formDataGeneration } from '../utils/utils';
import { generationImageAPI } from '../../app/thunks/generation';
import Tips from '../home/Tips';

function PickAnImageContent() {
  const dispatch = useDispatch();
  const generatedImages = useSelector((state) => state.generation.generatedImages);
  const imageParams = useSelector((state) => state.editor.imageParams);
  const [selectedImg, setSelectedImg] = useState(-1);

  const selectImage = (key) => {
    setSelectedImg((prevState) => {
      if (key === prevState) {
        return -1;
      }
      return key;
    });
  };

  const reGeneratehandle = async () => {
    const file = currentImageFile();

    const params = {
      width: imageParams.width,
      height: imageParams.height,
      prompt: imageParams.describe,
      nprompt: imageParams.negative_prompt,
      quantity: imageParams.quantity,
      image: imageParams.image,
    };

    const formData = formDataGeneration(params);
    dispatch(togglePickAnImagePage('generate'));
    await dispatch(generationImageAPI(formData));
  };

  const backBtnHandle = () => {
    dispatch(setSettings({ ...imageParams }));
    dispatch(togglePickAnImagePage('edit'));
  };

  const nextStepHandle = () => {
    dispatch(setCurrentImg(generatedImages[selectedImg > -1 ? selectedImg : 0]));
    dispatch(togglePickAnImagePage('improve'));
  };

  return (
    <>
      <div className="leftSectionEditorWrapper">
        <div className="leftSectionEditorContainer">
          <div className="topWrapper">
            <div className="titleWrapper">
              <span><PickAPhotoIcon /></span>
              <span className="titleText titleTextPick">
                Choose
                <span className="stageTxt stageTxtPick">2/3</span>
              </span>
            </div>
            <SectionBox title="Pick an image" isOpen>
              <div className="sectionBoxContainer">
                <div className="stylesContainer">
                  {generatedImages.map((item, key) => (
                    <StyleBox
                      key={item}
                      active={selectedImg === key}
                      onClick={() => selectImage(key)}
                      link={item}
                      className="pickAnImageIcon"
                    />
                  ))}
                </div>
              </div>
            </SectionBox>
            <SubmitButton
              title="Generate again"
              mode="white-mode"
              onClick={reGeneratehandle}
              icon="generate-icon.svg"
            />
            <SubmitButton title="Next" onClick={nextStepHandle} className="nextBtn" />
            <SubmitButton title="Back" onClick={backBtnHandle} className="white-mode" />
          </div>
        </div>
      </div>
      <div className="mainSectionEditorWrapper">
        <div className="pickAnImageWrapper">
          {generatedImages.map((item, key) => (
            <div
              key={item}
              className={`pickAnImage ${(selectedImg >= 0 && selectedImg !== key) && 'nonActiveImg'} `}
              onClick={() => selectImage(key)}
            >
              <img
                src={item}
                alt="Generated"
              />
            </div>
          ))}
        </div>
      </div>
      <div className="rightSectionEditorWrapper">
        <div className="pickImageRightSectionContainer">
          <Tips
            type="pickTip"
            title="Pick your image"
            desc="Would you look at that? You did great!
  GAIA generated 4 images for you. Pick the one that you like the most,
 and proceed to the final step, where you will be able to retouch your image before finalizing it."
            btnText="Got it!"
          />
        </div>
      </div>
    </>

  );
}

export default PickAnImageContent;
