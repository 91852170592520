import React from 'react';
import { ReactComponent as LoaderIcon } from '../../assets/images/icons/loader.svg';

function Loader({ title }) {
  return (
    <div className="loaderContainer">
      <span className="loaderIcon"><LoaderIcon /></span>
      <span className="loaderTxt">
        {title}
        {title && <span className="dots">...</span>}
      </span>
    </div>
  );
}

export default Loader;
